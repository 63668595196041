import { Cmp, Ctrl, log, state } from ":mods";
import { ROUTES } from "../const";
import { $course_data, $selected_stage, setCourseData, setSelectedModule } from "../store";
import { getStageListDetails } from "../apis";
import { ACourseStage } from "../models";
import { batch, createEffect } from "solid-js";
import { getModuleRoute } from "../methods";

export function SideStudentCourse(props: Ctrl.Nav.model.SideNavJSXItemProps) {
  //? TODO: state on events isn't working well on global states
  // $selected_stage.on((event, { value }) => {
  //   if (value === undefined || value === null) {
  //     setCourseData(undefined);
  //     return;
  //   }

  // });

  createEffect(() => {
    const stage_id = $selected_stage.value;
    // console.log("from side course nav $data :: ");
    if (stage_id === undefined || stage_id === null) {
      setCourseData(undefined);
    } else {
      //? TODO: will be better if there's an option from api to determine depth of details needed per api, could be done only through GraphQL i guess
      // console.log("from side course nav :: ", stage_id);
      getStageListDetails(stage_id).then(({ data }) => {
        // console.log("from side course nav getStageListDetails :: ", stage_id);
        setCourseData(data);
      });
    }
  });
  return (
    <Ctrl.Nav.SideElement
      base={props.base}
      disabled={!$course_data.value}
      disabledClass="!cursor-wait"
      groupClass="flex flex-row whitespace-nowrap"
      immediateLoad
      items={[
        ...$course_data.value?.modules?.map((module) => {
          // console.log("module route :: ", getModuleRoute(module.id, module.stage));
          return {
            title: module.name,
            to: getModuleRoute(module.id, module.stage),
            icon: {
              idle: "icon-local-nav-side:module-idle",
              active: "icon-local-nav-side:module-active",
            },
            // icon: "icon-local-nav-side:courses",
            class: "flex flex-row items-center",
            onActive: () => {
              // console.log("active module :: ", module);
              setSelectedModule(module);
              // $module_details.set(module);
            },
          };
        }),
      ]}
    >
      {$course_data.value === undefined ? "Loading Course Materials" : "Course Material"}
    </Ctrl.Nav.SideElement>
  );
}
